module.exports=[
 {
  "text": "Business architect",
  "type": "Role",
  "link": "/role/business-architect"
 },
 {
  "text": "Data architect",
  "type": "Role",
  "link": "/role/data-architect"
 },
 {
  "text": "Enterprise architect",
  "type": "Role",
  "link": "/role/enterprise-architect"
 },
 {
  "text": "Network architect",
  "type": "Role",
  "link": "/role/network-architect"
 },
 {
  "text": "Security architect",
  "type": "Role",
  "link": "/role/security-architect"
 },
 {
  "text": "Solution architect",
  "type": "Role",
  "link": "/role/solution-architect"
 },
 {
  "text": "Technical architect",
  "type": "Role",
  "link": "/role/technical-architect"
 },
 {
  "text": "Data analyst",
  "type": "Role",
  "link": "/role/data-analyst"
 },
 {
  "text": "Data engineer",
  "type": "Role",
  "link": "/role/data-engineer"
 },
 {
  "text": "Data ethicist",
  "type": "Role",
  "link": "/role/data-ethicist"
 },
 {
  "text": "Data governance manager",
  "type": "Role",
  "link": "/role/data-governance-manager"
 },
 {
  "text": "Data scientist",
  "type": "Role",
  "link": "/role/data-scientist"
 },
 {
  "text": "Performance analyst",
  "type": "Role",
  "link": "/role/performance-analyst"
 },
 {
  "text": "Application operations engineer",
  "type": "Role",
  "link": "/role/application-operations-engineer"
 },
 {
  "text": "Business relationship manager",
  "type": "Role",
  "link": "/role/business-relationship-manager"
 },
 {
  "text": "Change and release manager",
  "type": "Role",
  "link": "/role/change-and-release-manager"
 },
 {
  "text": "Command and control centre manager",
  "type": "Role",
  "link": "/role/command-and-control-centre-manager"
 },
 {
  "text": "End user computing engineer",
  "type": "Role",
  "link": "/role/end-user-computing-engineer"
 },
 {
  "text": "Incident manager",
  "type": "Role",
  "link": "/role/incident-manager"
 },
 {
  "text": "Infrastructure engineer",
  "type": "Role",
  "link": "/role/infrastructure-engineer"
 },
 {
  "text": "Infrastructure operations engineer",
  "type": "Role",
  "link": "/role/infrastructure-operations-engineer"
 },
 {
  "text": "IT service manager",
  "type": "Role",
  "link": "/role/it-service-manager"
 },
 {
  "text": "Problem manager",
  "type": "Role",
  "link": "/role/problem-manager"
 },
 {
  "text": "Service desk manager",
  "type": "Role",
  "link": "/role/service-desk-manager"
 },
 {
  "text": "Service transition manager",
  "type": "Role",
  "link": "/role/service-transition-manager"
 },
 {
  "text": "Business analyst",
  "type": "Role",
  "link": "/role/business-analyst"
 },
 {
  "text": "Delivery manager",
  "type": "Role",
  "link": "/role/delivery-manager"
 },
 {
  "text": "Digital portfolio manager",
  "type": "Role",
  "link": "/role/digital-portfolio-manager"
 },
 {
  "text": "Product manager",
  "type": "Role",
  "link": "/role/product-manager"
 },
 {
  "text": "Programme delivery manager",
  "type": "Role",
  "link": "/role/programme-delivery-manager"
 },
 {
  "text": "Service owner",
  "type": "Role",
  "link": "/role/service-owner"
 },
 {
  "text": "Quality assurance testing (QAT) analyst",
  "type": "Role",
  "link": "/role/quality-assurance-testing-qat-analyst"
 },
 {
  "text": "Test engineer",
  "type": "Role",
  "link": "/role/test-engineer"
 },
 {
  "text": "Test manager",
  "type": "Role",
  "link": "/role/test-manager"
 },
 {
  "text": "Development operations (DevOps) engineer",
  "type": "Role",
  "link": "/role/development-operations-devops-engineer"
 },
 {
  "text": "Frontend developer",
  "type": "Role",
  "link": "/role/frontend-developer"
 },
 {
  "text": "Software developer",
  "type": "Role",
  "link": "/role/software-developer"
 },
 {
  "text": "Accessibility specialist",
  "type": "Role",
  "link": "/role/accessibility-specialist"
 },
 {
  "text": "Content designer",
  "type": "Role",
  "link": "/role/content-designer"
 },
 {
  "text": "Content strategist",
  "type": "Role",
  "link": "/role/content-strategist"
 },
 {
  "text": "Graphic designer",
  "type": "Role",
  "link": "/role/graphic-designer"
 },
 {
  "text": "Interaction designer",
  "type": "Role",
  "link": "/role/interaction-designer"
 },
 {
  "text": "Service designer",
  "type": "Role",
  "link": "/role/service-designer"
 },
 {
  "text": "Technical writer",
  "type": "Role",
  "link": "/role/technical-writer"
 },
 {
  "text": "User researcher",
  "type": "Role",
  "link": "/role/user-researcher"
 },
 {
  "text": "Trainee business architect",
  "type": "Role Level",
  "link": "/role/business-architect#trainee-business-architect"
 },
 {
  "text": "Associate business architect",
  "type": "Role Level",
  "link": "/role/business-architect#associate-business-architect"
 },
 {
  "text": "Business architect",
  "type": "Role Level",
  "link": "/role/business-architect#business-architect"
 },
 {
  "text": "Lead business architect",
  "type": "Role Level",
  "link": "/role/business-architect#lead-business-architect"
 },
 {
  "text": "Data architect",
  "type": "Role Level",
  "link": "/role/data-architect#data-architect"
 },
 {
  "text": "Senior data architect",
  "type": "Role Level",
  "link": "/role/data-architect#senior-data-architect"
 },
 {
  "text": "Chief data architect",
  "type": "Role Level",
  "link": "/role/data-architect#chief-data-architect"
 },
 {
  "text": "Enterprise architect",
  "type": "Role Level",
  "link": "/role/enterprise-architect#enterprise-architect"
 },
 {
  "text": "Senior enterprise architect",
  "type": "Role Level",
  "link": "/role/enterprise-architect#senior-enterprise-architect"
 },
 {
  "text": "Lead enterprise architect",
  "type": "Role Level",
  "link": "/role/enterprise-architect#lead-enterprise-architect"
 },
 {
  "text": "Principal enterprise architect",
  "type": "Role Level",
  "link": "/role/enterprise-architect#principal-enterprise-architect"
 },
 {
  "text": "Associate network architect",
  "type": "Role Level",
  "link": "/role/network-architect#associate-network-architect"
 },
 {
  "text": "Network architect",
  "type": "Role Level",
  "link": "/role/network-architect#network-architect"
 },
 {
  "text": "Lead network architect",
  "type": "Role Level",
  "link": "/role/network-architect#lead-network-architect"
 },
 {
  "text": "Security architect",
  "type": "Role Level",
  "link": "/role/security-architect#security-architect"
 },
 {
  "text": "Lead security architect",
  "type": "Role Level",
  "link": "/role/security-architect#lead-security-architect"
 },
 {
  "text": "Principal security architect",
  "type": "Role Level",
  "link": "/role/security-architect#principal-security-architect"
 },
 {
  "text": "Associate solution architect",
  "type": "Role Level",
  "link": "/role/solution-architect#associate-solution-architect"
 },
 {
  "text": "Solution architect",
  "type": "Role Level",
  "link": "/role/solution-architect#solution-architect"
 },
 {
  "text": "Senior solution architect",
  "type": "Role Level",
  "link": "/role/solution-architect#senior-solution-architect"
 },
 {
  "text": "Lead solution architect",
  "type": "Role Level",
  "link": "/role/solution-architect#lead-solution-architect"
 },
 {
  "text": "Principal solution architect",
  "type": "Role Level",
  "link": "/role/solution-architect#principal-solution-architect"
 },
 {
  "text": "Associate technical architect",
  "type": "Role Level",
  "link": "/role/technical-architect#associate-technical-architect"
 },
 {
  "text": "Technical architect",
  "type": "Role Level",
  "link": "/role/technical-architect#technical-architect"
 },
 {
  "text": "Senior technical architect",
  "type": "Role Level",
  "link": "/role/technical-architect#senior-technical-architect"
 },
 {
  "text": "Lead technical architect",
  "type": "Role Level",
  "link": "/role/technical-architect#lead-technical-architect"
 },
 {
  "text": "Principal technical architect",
  "type": "Role Level",
  "link": "/role/technical-architect#principal-technical-architect"
 },
 {
  "text": "Associate analyst",
  "type": "Role Level",
  "link": "/role/data-analyst#associate-analyst"
 },
 {
  "text": "Data analyst",
  "type": "Role Level",
  "link": "/role/data-analyst#data-analyst"
 },
 {
  "text": "Senior data analyst",
  "type": "Role Level",
  "link": "/role/data-analyst#senior-data-analyst"
 },
 {
  "text": "Principal data analyst",
  "type": "Role Level",
  "link": "/role/data-analyst#principal-data-analyst"
 },
 {
  "text": "Data engineer",
  "type": "Role Level",
  "link": "/role/data-engineer#data-engineer"
 },
 {
  "text": "Senior data engineer",
  "type": "Role Level",
  "link": "/role/data-engineer#senior-data-engineer"
 },
 {
  "text": "Lead data engineer",
  "type": "Role Level",
  "link": "/role/data-engineer#lead-data-engineer"
 },
 {
  "text": "Head of data engineering",
  "type": "Role Level",
  "link": "/role/data-engineer#head-of-data-engineering"
 },
 {
  "text": "Data ethics lead",
  "type": "Role Level",
  "link": "/role/data-ethicist#data-ethics-lead"
 },
 {
  "text": "Head of data ethics",
  "type": "Role Level",
  "link": "/role/data-ethicist#head-of-data-ethics"
 },
 {
  "text": "Data governance manager",
  "type": "Role Level",
  "link": "/role/data-governance-manager#data-governance-manager"
 },
 {
  "text": "Lead data governance manager",
  "type": "Role Level",
  "link": "/role/data-governance-manager#lead-data-governance-manager"
 },
 {
  "text": "Head of data governance",
  "type": "Role Level",
  "link": "/role/data-governance-manager#head-of-data-governance"
 },
 {
  "text": "Trainee data scientist",
  "type": "Role Level",
  "link": "/role/data-scientist#trainee-data-scientist"
 },
 {
  "text": "Associate data scientist",
  "type": "Role Level",
  "link": "/role/data-scientist#associate-data-scientist"
 },
 {
  "text": "Data scientist",
  "type": "Role Level",
  "link": "/role/data-scientist#data-scientist"
 },
 {
  "text": "Principal data scientist",
  "type": "Role Level",
  "link": "/role/data-scientist#principal-data-scientist"
 },
 {
  "text": "Lead data scientist",
  "type": "Role Level",
  "link": "/role/data-scientist#lead-data-scientist"
 },
 {
  "text": "Head of data science",
  "type": "Role Level",
  "link": "/role/data-scientist#head-of-data-science"
 },
 {
  "text": "Associate performance analyst",
  "type": "Role Level",
  "link": "/role/performance-analyst#associate-performance-analyst"
 },
 {
  "text": "Performance analyst",
  "type": "Role Level",
  "link": "/role/performance-analyst#performance-analyst"
 },
 {
  "text": "Senior performance analyst",
  "type": "Role Level",
  "link": "/role/performance-analyst#senior-performance-analyst"
 },
 {
  "text": "Lead performance analyst",
  "type": "Role Level",
  "link": "/role/performance-analyst#lead-performance-analyst"
 },
 {
  "text": "Head of performance analysis",
  "type": "Role Level",
  "link": "/role/performance-analyst#head-of-performance-analysis"
 },
 {
  "text": "Associate application operations engineer",
  "type": "Role Level",
  "link": "/role/application-operations-engineer#associate-application-operations-engineer"
 },
 {
  "text": "Application operations engineer",
  "type": "Role Level",
  "link": "/role/application-operations-engineer#application-operations-engineer"
 },
 {
  "text": "Senior application operations engineer",
  "type": "Role Level",
  "link": "/role/application-operations-engineer#senior-application-operations-engineer"
 },
 {
  "text": "Lead application operations engineer",
  "type": "Role Level",
  "link": "/role/application-operations-engineer#lead-application-operations-engineer"
 },
 {
  "text": "Principal application operations engineer",
  "type": "Role Level",
  "link": "/role/application-operations-engineer#principal-application-operations-engineer"
 },
 {
  "text": "Business relationship manager",
  "type": "Role Level",
  "link": "/role/business-relationship-manager#business-relationship-manager"
 },
 {
  "text": "Senior business relationship manager",
  "type": "Role Level",
  "link": "/role/business-relationship-manager#senior-business-relationship-manager"
 },
 {
  "text": "Lead business relationship manager",
  "type": "Role Level",
  "link": "/role/business-relationship-manager#lead-business-relationship-manager"
 },
 {
  "text": "Configuration analyst",
  "type": "Role Level",
  "link": "/role/change-and-release-manager#configuration-analyst"
 },
 {
  "text": "Change and release analyst",
  "type": "Role Level",
  "link": "/role/change-and-release-manager#change-and-release-analyst"
 },
 {
  "text": "Change and release manager",
  "type": "Role Level",
  "link": "/role/change-and-release-manager#change-and-release-manager"
 },
 {
  "text": "Operations analyst",
  "type": "Role Level",
  "link": "/role/command-and-control-centre-manager#operations-analyst"
 },
 {
  "text": "Senior operations analyst",
  "type": "Role Level",
  "link": "/role/command-and-control-centre-manager#senior-operations-analyst"
 },
 {
  "text": "Operational control manager",
  "type": "Role Level",
  "link": "/role/command-and-control-centre-manager#operational-control-manager"
 },
 {
  "text": "Head of command and control",
  "type": "Role Level",
  "link": "/role/command-and-control-centre-manager#head-of-command-and-control"
 },
 {
  "text": "Associate end user computing engineer",
  "type": "Role Level",
  "link": "/role/end-user-computing-engineer#associate-end-user-computing-engineer"
 },
 {
  "text": "End user computing engineer",
  "type": "Role Level",
  "link": "/role/end-user-computing-engineer#end-user-computing-engineer"
 },
 {
  "text": "Senior end user computing engineer",
  "type": "Role Level",
  "link": "/role/end-user-computing-engineer#senior-end-user-computing-engineer"
 },
 {
  "text": "Lead end user computing engineer",
  "type": "Role Level",
  "link": "/role/end-user-computing-engineer#lead-end-user-computing-engineer"
 },
 {
  "text": "Principal end user computing engineer",
  "type": "Role Level",
  "link": "/role/end-user-computing-engineer#principal-end-user-computing-engineer"
 },
 {
  "text": "Incident manager",
  "type": "Role Level",
  "link": "/role/incident-manager#incident-manager"
 },
 {
  "text": "Major incident manager",
  "type": "Role Level",
  "link": "/role/incident-manager#major-incident-manager"
 },
 {
  "text": "Associate infrastructure engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-engineer#associate-infrastructure-engineer"
 },
 {
  "text": "Infrastructure engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-engineer#infrastructure-engineer"
 },
 {
  "text": "Senior infrastructure engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-engineer#senior-infrastructure-engineer"
 },
 {
  "text": "Lead infrastructure engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-engineer#lead-infrastructure-engineer"
 },
 {
  "text": "Principal infrastructure engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-engineer#principal-infrastructure-engineer"
 },
 {
  "text": "Associate infrastructure operations engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-operations-engineer#associate-infrastructure-operations-engineer"
 },
 {
  "text": "Infrastructure operations engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-operations-engineer#infrastructure-operations-engineer"
 },
 {
  "text": "Senior infrastructure operations engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-operations-engineer#senior-infrastructure-operations-engineer"
 },
 {
  "text": "Lead infrastructure operations engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-operations-engineer#lead-infrastructure-operations-engineer"
 },
 {
  "text": "Principal infrastructure operations engineer",
  "type": "Role Level",
  "link": "/role/infrastructure-operations-engineer#principal-infrastructure-operations-engineer"
 },
 {
  "text": "IT service analyst",
  "type": "Role Level",
  "link": "/role/it-service-manager#it-service-analyst"
 },
 {
  "text": "IT service manager",
  "type": "Role Level",
  "link": "/role/it-service-manager#it-service-manager"
 },
 {
  "text": "Senior IT service manager",
  "type": "Role Level",
  "link": "/role/it-service-manager#senior-it-service-manager"
 },
 {
  "text": "Head of IT service management",
  "type": "Role Level",
  "link": "/role/it-service-manager#head-of-it-service-management"
 },
 {
  "text": "Problem analyst",
  "type": "Role Level",
  "link": "/role/problem-manager#problem-analyst"
 },
 {
  "text": "Problem manager",
  "type": "Role Level",
  "link": "/role/problem-manager#problem-manager"
 },
 {
  "text": "Service desk analyst",
  "type": "Role Level",
  "link": "/role/service-desk-manager#service-desk-analyst"
 },
 {
  "text": "Senior service desk analyst",
  "type": "Role Level",
  "link": "/role/service-desk-manager#senior-service-desk-analyst"
 },
 {
  "text": "Service desk manager",
  "type": "Role Level",
  "link": "/role/service-desk-manager#service-desk-manager"
 },
 {
  "text": "Head of service desk",
  "type": "Role Level",
  "link": "/role/service-desk-manager#head-of-service-desk"
 },
 {
  "text": "Service acceptance analyst",
  "type": "Role Level",
  "link": "/role/service-transition-manager#service-acceptance-analyst"
 },
 {
  "text": "Service readiness analyst",
  "type": "Role Level",
  "link": "/role/service-transition-manager#service-readiness-analyst"
 },
 {
  "text": "Service transition manager",
  "type": "Role Level",
  "link": "/role/service-transition-manager#service-transition-manager"
 },
 {
  "text": "Lead service transition manager",
  "type": "Role Level",
  "link": "/role/service-transition-manager#lead-service-transition-manager"
 },
 {
  "text": "Trainee business analyst",
  "type": "Role Level",
  "link": "/role/business-analyst#trainee-business-analyst"
 },
 {
  "text": "Junior business analyst",
  "type": "Role Level",
  "link": "/role/business-analyst#junior-business-analyst"
 },
 {
  "text": "Business analyst",
  "type": "Role Level",
  "link": "/role/business-analyst#business-analyst"
 },
 {
  "text": "Senior business analyst",
  "type": "Role Level",
  "link": "/role/business-analyst#senior-business-analyst"
 },
 {
  "text": "Lead business analyst",
  "type": "Role Level",
  "link": "/role/business-analyst#lead-business-analyst"
 },
 {
  "text": "Head of business analysis",
  "type": "Role Level",
  "link": "/role/business-analyst#head-of-business-analysis"
 },
 {
  "text": "Associate delivery manager",
  "type": "Role Level",
  "link": "/role/delivery-manager#associate-delivery-manager"
 },
 {
  "text": "Delivery manager",
  "type": "Role Level",
  "link": "/role/delivery-manager#delivery-manager"
 },
 {
  "text": "Senior delivery manager",
  "type": "Role Level",
  "link": "/role/delivery-manager#senior-delivery-manager"
 },
 {
  "text": "Head of (Agile) delivery management",
  "type": "Role Level",
  "link": "/role/delivery-manager#head-of-agile-delivery-management"
 },
 {
  "text": "Digital portfolio analyst",
  "type": "Role Level",
  "link": "/role/digital-portfolio-manager#digital-portfolio-analyst"
 },
 {
  "text": "Digital portfolio manager",
  "type": "Role Level",
  "link": "/role/digital-portfolio-manager#digital-portfolio-manager"
 },
 {
  "text": "Senior digital portfolio manager",
  "type": "Role Level",
  "link": "/role/digital-portfolio-manager#senior-digital-portfolio-manager"
 },
 {
  "text": "Head of portolio",
  "type": "Role Level",
  "link": "/role/digital-portfolio-manager#head-of-portolio"
 },
 {
  "text": "Associate product manager",
  "type": "Role Level",
  "link": "/role/product-manager#associate-product-manager"
 },
 {
  "text": "Product manager",
  "type": "Role Level",
  "link": "/role/product-manager#product-manager"
 },
 {
  "text": "Senior product manager",
  "type": "Role Level",
  "link": "/role/product-manager#senior-product-manager"
 },
 {
  "text": "Lead product manager",
  "type": "Role Level",
  "link": "/role/product-manager#lead-product-manager"
 },
 {
  "text": "Head of product",
  "type": "Role Level",
  "link": "/role/product-manager#head-of-product"
 },
 {
  "text": "Programme delivery manager",
  "type": "Role Level",
  "link": "/role/programme-delivery-manager#programme-delivery-manager"
 },
 {
  "text": "Service owner",
  "type": "Role Level",
  "link": "/role/service-owner#service-owner"
 },
 {
  "text": "Tester (QAT)",
  "type": "Role Level",
  "link": "/role/quality-assurance-testing-qat-analyst#tester-qat"
 },
 {
  "text": "QAT analyst",
  "type": "Role Level",
  "link": "/role/quality-assurance-testing-qat-analyst#qat-analyst"
 },
 {
  "text": "Senior QAT analyst",
  "type": "Role Level",
  "link": "/role/quality-assurance-testing-qat-analyst#senior-qat-analyst"
 },
 {
  "text": "Lead QAT analyst",
  "type": "Role Level",
  "link": "/role/quality-assurance-testing-qat-analyst#lead-qat-analyst"
 },
 {
  "text": "Tester (test engineer)",
  "type": "Role Level",
  "link": "/role/test-engineer#tester-test-engineer"
 },
 {
  "text": "Test engineer",
  "type": "Role Level",
  "link": "/role/test-engineer#test-engineer"
 },
 {
  "text": "Senior test engineer",
  "type": "Role Level",
  "link": "/role/test-engineer#senior-test-engineer"
 },
 {
  "text": "Lead test engineer",
  "type": "Role Level",
  "link": "/role/test-engineer#lead-test-engineer"
 },
 {
  "text": "Test manager",
  "type": "Role Level",
  "link": "/role/test-manager#test-manager"
 },
 {
  "text": "Head of test",
  "type": "Role Level",
  "link": "/role/test-manager#head-of-test"
 },
 {
  "text": "Apprentice DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#apprentice-devops-engineer"
 },
 {
  "text": "Junior DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#junior-devops-engineer"
 },
 {
  "text": "DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#devops-engineer"
 },
 {
  "text": "Senior DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#senior-devops-engineer"
 },
 {
  "text": "Senior DevOps engineer - management",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#senior-devops-engineer---management"
 },
 {
  "text": "Lead DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#lead-devops-engineer"
 },
 {
  "text": "Lead DevOps engineer - management",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#lead-devops-engineer---management"
 },
 {
  "text": "Principal DevOps engineer",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#principal-devops-engineer"
 },
 {
  "text": "Principal DevOps engineer - management",
  "type": "Role Level",
  "link": "/role/development-operations-devops-engineer#principal-devops-engineer---management"
 },
 {
  "text": "Apprentice frontend developer",
  "type": "Role Level",
  "link": "/role/frontend-developer#apprentice-frontend-developer"
 },
 {
  "text": "Junior frontend developer",
  "type": "Role Level",
  "link": "/role/frontend-developer#junior-frontend-developer"
 },
 {
  "text": "Frontend developer",
  "type": "Role Level",
  "link": "/role/frontend-developer#frontend-developer"
 },
 {
  "text": "Senior frontend developer",
  "type": "Role Level",
  "link": "/role/frontend-developer#senior-frontend-developer"
 },
 {
  "text": "Lead frontend developer",
  "type": "Role Level",
  "link": "/role/frontend-developer#lead-frontend-developer"
 },
 {
  "text": "Head of frontend development",
  "type": "Role Level",
  "link": "/role/frontend-developer#head-of-frontend-development"
 },
 {
  "text": "Apprentice developer",
  "type": "Role Level",
  "link": "/role/software-developer#apprentice-developer"
 },
 {
  "text": "Junior developer",
  "type": "Role Level",
  "link": "/role/software-developer#junior-developer"
 },
 {
  "text": "Developer",
  "type": "Role Level",
  "link": "/role/software-developer#developer"
 },
 {
  "text": "Senior developer",
  "type": "Role Level",
  "link": "/role/software-developer#senior-developer"
 },
 {
  "text": "Senior developer - management",
  "type": "Role Level",
  "link": "/role/software-developer#senior-developer---management"
 },
 {
  "text": "Lead developer",
  "type": "Role Level",
  "link": "/role/software-developer#lead-developer"
 },
 {
  "text": "Lead developer - management",
  "type": "Role Level",
  "link": "/role/software-developer#lead-developer---management"
 },
 {
  "text": "Principal developer",
  "type": "Role Level",
  "link": "/role/software-developer#principal-developer"
 },
 {
  "text": "Principal developer - management",
  "type": "Role Level",
  "link": "/role/software-developer#principal-developer---management"
 },
 {
  "text": "Junior accessibility specialist",
  "type": "Role Level",
  "link": "/role/accessibility-specialist#junior-accessibility-specialist"
 },
 {
  "text": "Accessibility specialist",
  "type": "Role Level",
  "link": "/role/accessibility-specialist#accessibility-specialist"
 },
 {
  "text": "Senior accessibility specialist",
  "type": "Role Level",
  "link": "/role/accessibility-specialist#senior-accessibility-specialist"
 },
 {
  "text": "Head of accessibility",
  "type": "Role Level",
  "link": "/role/accessibility-specialist#head-of-accessibility"
 },
 {
  "text": "Associate content designer",
  "type": "Role Level",
  "link": "/role/content-designer#associate-content-designer"
 },
 {
  "text": "Junior content designer",
  "type": "Role Level",
  "link": "/role/content-designer#junior-content-designer"
 },
 {
  "text": "Content designer",
  "type": "Role Level",
  "link": "/role/content-designer#content-designer"
 },
 {
  "text": "Senior content designer",
  "type": "Role Level",
  "link": "/role/content-designer#senior-content-designer"
 },
 {
  "text": "Lead content designer",
  "type": "Role Level",
  "link": "/role/content-designer#lead-content-designer"
 },
 {
  "text": "Head of content design",
  "type": "Role Level",
  "link": "/role/content-designer#head-of-content-design"
 },
 {
  "text": "Content strategist",
  "type": "Role Level",
  "link": "/role/content-strategist#content-strategist"
 },
 {
  "text": "Associate graphic designer",
  "type": "Role Level",
  "link": "/role/graphic-designer#associate-graphic-designer"
 },
 {
  "text": "Junior graphic designer",
  "type": "Role Level",
  "link": "/role/graphic-designer#junior-graphic-designer"
 },
 {
  "text": "Graphic designer",
  "type": "Role Level",
  "link": "/role/graphic-designer#graphic-designer"
 },
 {
  "text": "Senior graphic designer",
  "type": "Role Level",
  "link": "/role/graphic-designer#senior-graphic-designer"
 },
 {
  "text": "Lead graphic designer",
  "type": "Role Level",
  "link": "/role/graphic-designer#lead-graphic-designer"
 },
 {
  "text": "Head of graphic design",
  "type": "Role Level",
  "link": "/role/graphic-designer#head-of-graphic-design"
 },
 {
  "text": "Associate interaction designer",
  "type": "Role Level",
  "link": "/role/interaction-designer#associate-interaction-designer"
 },
 {
  "text": "Junior interaction designer",
  "type": "Role Level",
  "link": "/role/interaction-designer#junior-interaction-designer"
 },
 {
  "text": "Interaction designer",
  "type": "Role Level",
  "link": "/role/interaction-designer#interaction-designer"
 },
 {
  "text": "Senior interaction designer",
  "type": "Role Level",
  "link": "/role/interaction-designer#senior-interaction-designer"
 },
 {
  "text": "Lead interaction designer",
  "type": "Role Level",
  "link": "/role/interaction-designer#lead-interaction-designer"
 },
 {
  "text": "Head of interaction design",
  "type": "Role Level",
  "link": "/role/interaction-designer#head-of-interaction-design"
 },
 {
  "text": "Associate service designer",
  "type": "Role Level",
  "link": "/role/service-designer#associate-service-designer"
 },
 {
  "text": "Junior service designer",
  "type": "Role Level",
  "link": "/role/service-designer#junior-service-designer"
 },
 {
  "text": "Service designer",
  "type": "Role Level",
  "link": "/role/service-designer#service-designer"
 },
 {
  "text": "Senior service designer",
  "type": "Role Level",
  "link": "/role/service-designer#senior-service-designer"
 },
 {
  "text": "Lead service designer",
  "type": "Role Level",
  "link": "/role/service-designer#lead-service-designer"
 },
 {
  "text": "Head of service design",
  "type": "Role Level",
  "link": "/role/service-designer#head-of-service-design"
 },
 {
  "text": "Technical writer",
  "type": "Role Level",
  "link": "/role/technical-writer#technical-writer"
 },
 {
  "text": "Lead technical writer",
  "type": "Role Level",
  "link": "/role/technical-writer#lead-technical-writer"
 },
 {
  "text": "Associate user researcher",
  "type": "Role Level",
  "link": "/role/user-researcher#associate-user-researcher"
 },
 {
  "text": "Junior user researcher",
  "type": "Role Level",
  "link": "/role/user-researcher#junior-user-researcher"
 },
 {
  "text": "User researcher",
  "type": "Role Level",
  "link": "/role/user-researcher#user-researcher"
 },
 {
  "text": "Senior user researcher",
  "type": "Role Level",
  "link": "/role/user-researcher#senior-user-researcher"
 },
 {
  "text": "Lead user researcher",
  "type": "Role Level",
  "link": "/role/user-researcher#lead-user-researcher"
 },
 {
  "text": "Head of user research",
  "type": "Role Level",
  "link": "/role/user-researcher#head-of-user-research"
 },
 {
  "text": "Business architecture",
  "type": "Skill",
  "link": "/skills#['business-architecture']"
 },
 {
  "text": "Communicating information",
  "type": "Skill",
  "link": "/skills#['communicating-information']"
 },
 {
  "text": "Consultancy",
  "type": "Skill",
  "link": "/skills#['consultancy']"
 },
 {
  "text": "Making and guiding decisions",
  "type": "Skill",
  "link": "/skills#['making-and-guiding-decisions']"
 },
 {
  "text": "Methods and tools",
  "type": "Skill",
  "link": "/skills#['methods-and-tools']"
 },
 {
  "text": "Strategic thinking",
  "type": "Skill",
  "link": "/skills#['strategic-thinking']"
 },
 {
  "text": "Stakeholder relationship management",
  "type": "Skill",
  "link": "/skills#['stakeholder-relationship-management']"
 },
 {
  "text": "Strategic design and business change",
  "type": "Skill",
  "link": "/skills#['strategic-design-and-business-change']"
 },
 {
  "text": "Communicating between the technical and non-technical",
  "type": "Skill",
  "link": "/skills#['communicating-between-the-technical-and-non-technical']"
 },
 {
  "text": "Communicating data",
  "type": "Skill",
  "link": "/skills#['communicating-data']"
 },
 {
  "text": "Data analysis and synthesis",
  "type": "Skill",
  "link": "/skills#['data-analysis-and-synthesis']"
 },
 {
  "text": "Data governance (data architect)",
  "type": "Skill",
  "link": "/skills#['data-governance-data-architect']"
 },
 {
  "text": "Data innovation",
  "type": "Skill",
  "link": "/skills#['data-innovation']"
 },
 {
  "text": "Data modelling",
  "type": "Skill",
  "link": "/skills#['data-modelling']"
 },
 {
  "text": "Data standards (data architect)",
  "type": "Skill",
  "link": "/skills#['data-standards-data-architect']"
 },
 {
  "text": "Metadata management",
  "type": "Skill",
  "link": "/skills#['metadata-management']"
 },
 {
  "text": "Problem management",
  "type": "Skill",
  "link": "/skills#['problem-management']"
 },
 {
  "text": "Turning business problems into data design",
  "type": "Skill",
  "link": "/skills#['turning-business-problems-into-data-design']"
 },
 {
  "text": "Architect for the whole context",
  "type": "Skill",
  "link": "/skills#['architect-for-the-whole-context']"
 },
 {
  "text": "Architecture communication",
  "type": "Skill",
  "link": "/skills#['architecture-communication']"
 },
 {
  "text": "Commercial perspective",
  "type": "Skill",
  "link": "/skills#['commercial-perspective']"
 },
 {
  "text": "Community collaboration",
  "type": "Skill",
  "link": "/skills#['community-collaboration']"
 },
 {
  "text": "Enterprise architecture",
  "type": "Skill",
  "link": "/skills#['enterprise-architecture']"
 },
 {
  "text": "Making architectural decisions",
  "type": "Skill",
  "link": "/skills#['making-architectural-decisions']"
 },
 {
  "text": "Problem definition and shaping",
  "type": "Skill",
  "link": "/skills#['problem-definition-and-shaping']"
 },
 {
  "text": "Strategy design",
  "type": "Skill",
  "link": "/skills#['strategy-design']"
 },
 {
  "text": "Governance and assurance",
  "type": "Skill",
  "link": "/skills#['governance-and-assurance']"
 },
 {
  "text": "IT infrastructure (network architect)",
  "type": "Skill",
  "link": "/skills#['it-infrastructure-network-architect']"
 },
 {
  "text": "Systems design",
  "type": "Skill",
  "link": "/skills#['systems-design']"
 },
 {
  "text": "Systems integration (network architect)",
  "type": "Skill",
  "link": "/skills#['systems-integration-network-architect']"
 },
 {
  "text": "Technical understanding",
  "type": "Skill",
  "link": "/skills#['technical-understanding']"
 },
 {
  "text": "Troubleshooting and problem resolution",
  "type": "Skill",
  "link": "/skills#['troubleshooting-and-problem-resolution']"
 },
 {
  "text": "Analysis",
  "type": "Skill",
  "link": "/skills#['analysis']"
 },
 {
  "text": "Communication (security architect)",
  "type": "Skill",
  "link": "/skills#['communication-security-architect']"
 },
 {
  "text": "Designing secure systems",
  "type": "Skill",
  "link": "/skills#['designing-secure-systems']"
 },
 {
  "text": "Enabling and informing risk-based decisions",
  "type": "Skill",
  "link": "/skills#['enabling-and-informing-risk-based-decisions']"
 },
 {
  "text": "Research and innovation",
  "type": "Skill",
  "link": "/skills#['research-and-innovation']"
 },
 {
  "text": "Security technology",
  "type": "Skill",
  "link": "/skills#['security-technology']"
 },
 {
  "text": "Understanding security implications of transformation",
  "type": "Skill",
  "link": "/skills#['understanding-security-implications-of-transformation']"
 },
 {
  "text": "Technical design throughout the life cycle",
  "type": "Skill",
  "link": "/skills#['technical-design-throughout-the-life-cycle']"
 },
 {
  "text": "Analysis and synthesis (data analyst)",
  "type": "Skill",
  "link": "/skills#['analysis-and-synthesis-data-analyst']"
 },
 {
  "text": "Communication",
  "type": "Skill",
  "link": "/skills#['communication']"
 },
 {
  "text": "Data management",
  "type": "Skill",
  "link": "/skills#['data-management']"
 },
 {
  "text": "Data modelling, cleansing and enrichment",
  "type": "Skill",
  "link": "/skills#['data-modelling-cleansing-and-enrichment']"
 },
 {
  "text": "Data quality assurance, validation and linkage",
  "type": "Skill",
  "link": "/skills#['data-quality-assurance-validation-and-linkage']"
 },
 {
  "text": "Data visualisation",
  "type": "Skill",
  "link": "/skills#['data-visualisation']"
 },
 {
  "text": "IT and mathematics",
  "type": "Skill",
  "link": "/skills#['it-and-mathematics']"
 },
 {
  "text": "Logical and creative thinking",
  "type": "Skill",
  "link": "/skills#['logical-and-creative-thinking']"
 },
 {
  "text": "Project management",
  "type": "Skill",
  "link": "/skills#['project-management']"
 },
 {
  "text": "Statistical methods and data analysis",
  "type": "Skill",
  "link": "/skills#['statistical-methods-and-data-analysis']"
 },
 {
  "text": "Data development process",
  "type": "Skill",
  "link": "/skills#['data-development-process']"
 },
 {
  "text": "Data integration design",
  "type": "Skill",
  "link": "/skills#['data-integration-design']"
 },
 {
  "text": "Problem resolution (data)",
  "type": "Skill",
  "link": "/skills#['problem-resolution-data']"
 },
 {
  "text": "Programming and build (data engineering)",
  "type": "Skill",
  "link": "/skills#['programming-and-build-data-engineering']"
 },
 {
  "text": "Testing",
  "type": "Skill",
  "link": "/skills#['testing']"
 },
 {
  "text": "Analysis and synthesis (data ethics)",
  "type": "Skill",
  "link": "/skills#['analysis-and-synthesis-data-ethics']"
 },
 {
  "text": "Applied knowledge of social sciences",
  "type": "Skill",
  "link": "/skills#['applied-knowledge-of-social-sciences']"
 },
 {
  "text": "Communication (data ethics)",
  "type": "Skill",
  "link": "/skills#['communication-data-ethics']"
 },
 {
  "text": "Empathy and inclusivity",
  "type": "Skill",
  "link": "/skills#['empathy-and-inclusivity']"
 },
 {
  "text": "Ethics and privacy",
  "type": "Skill",
  "link": "/skills#['ethics-and-privacy']"
 },
 {
  "text": "Managing decisions and risks",
  "type": "Skill",
  "link": "/skills#['managing-decisions-and-risks']"
 },
 {
  "text": "Product ownership (data ethics)",
  "type": "Skill",
  "link": "/skills#['product-ownership-data-ethics']"
 },
 {
  "text": "Communicating analysis and insight",
  "type": "Skill",
  "link": "/skills#['communicating-analysis-and-insight']"
 },
 {
  "text": "Data governance",
  "type": "Skill",
  "link": "/skills#['data-governance']"
 },
 {
  "text": "Data life cycle",
  "type": "Skill",
  "link": "/skills#['data-life-cycle']"
 },
 {
  "text": "Data literacy improvement",
  "type": "Skill",
  "link": "/skills#['data-literacy-improvement']"
 },
 {
  "text": "Data maturity models",
  "type": "Skill",
  "link": "/skills#['data-maturity-models']"
 },
 {
  "text": "Data regulation and ethics",
  "type": "Skill",
  "link": "/skills#['data-regulation-and-ethics']"
 },
 {
  "text": "Data standards",
  "type": "Skill",
  "link": "/skills#['data-standards']"
 },
 {
  "text": "Applied maths, statistics and scientific practices",
  "type": "Skill",
  "link": "/skills#['applied-maths-statistics-and-scientific-practices']"
 },
 {
  "text": "Data engineering and manipulation",
  "type": "Skill",
  "link": "/skills#['data-engineering-and-manipulation']"
 },
 {
  "text": "Data science innovation",
  "type": "Skill",
  "link": "/skills#['data-science-innovation']"
 },
 {
  "text": "Delivering business impact",
  "type": "Skill",
  "link": "/skills#['delivering-business-impact']"
 },
 {
  "text": "Developing data science capability",
  "type": "Skill",
  "link": "/skills#['developing-data-science-capability']"
 },
 {
  "text": "Ethics and privacy (data science)",
  "type": "Skill",
  "link": "/skills#['ethics-and-privacy-data-science']"
 },
 {
  "text": "Programming and build (data science)",
  "type": "Skill",
  "link": "/skills#['programming-and-build-data-science']"
 },
 {
  "text": "Understanding product delivery",
  "type": "Skill",
  "link": "/skills#['understanding-product-delivery']"
 },
 {
  "text": "Analysis and insight",
  "type": "Skill",
  "link": "/skills#['analysis-and-insight']"
 },
 {
  "text": "Performance measurement",
  "type": "Skill",
  "link": "/skills#['performance-measurement']"
 },
 {
  "text": "Technical understanding (performance analyst)",
  "type": "Skill",
  "link": "/skills#['technical-understanding-performance-analyst']"
 },
 {
  "text": "Understanding analysis across the product life cycle",
  "type": "Skill",
  "link": "/skills#['understanding-analysis-across-the-product-life-cycle']"
 },
 {
  "text": "User-centred analysis",
  "type": "Skill",
  "link": "/skills#['user-centred-analysis']"
 },
 {
  "text": "Verification and validation of data and analysis",
  "type": "Skill",
  "link": "/skills#['verification-and-validation-of-data-and-analysis']"
 },
 {
  "text": "Working within constraints (performance analyst)",
  "type": "Skill",
  "link": "/skills#['working-within-constraints-performance-analyst']"
 },
 {
  "text": "Incident management",
  "type": "Skill",
  "link": "/skills#['incident-management']"
 },
 {
  "text": "Ownership and initiative",
  "type": "Skill",
  "link": "/skills#['ownership-and-initiative']"
 },
 {
  "text": "User focus",
  "type": "Skill",
  "link": "/skills#['user-focus']"
 },
 {
  "text": "Asset and configuration management",
  "type": "Skill",
  "link": "/skills#['asset-and-configuration-management']"
 },
 {
  "text": "Availability and capacity management",
  "type": "Skill",
  "link": "/skills#['availability-and-capacity-management']"
 },
 {
  "text": "Change management",
  "type": "Skill",
  "link": "/skills#['change-management']"
 },
 {
  "text": "Continual service improvement",
  "type": "Skill",
  "link": "/skills#['continual-service-improvement']"
 },
 {
  "text": "Service focus",
  "type": "Skill",
  "link": "/skills#['service-focus']"
 },
 {
  "text": "Service management framework knowledge",
  "type": "Skill",
  "link": "/skills#['service-management-framework-knowledge']"
 },
 {
  "text": "Technical specialism",
  "type": "Skill",
  "link": "/skills#['technical-specialism']"
 },
 {
  "text": "Business analysis (IT operations)",
  "type": "Skill",
  "link": "/skills#['business-analysis-it-operations']"
 },
 {
  "text": "Service reporting",
  "type": "Skill",
  "link": "/skills#['service-reporting']"
 },
 {
  "text": "Stakeholder relationship management (IT operations)",
  "type": "Skill",
  "link": "/skills#['stakeholder-relationship-management-it-operations']"
 },
 {
  "text": "Continuity management",
  "type": "Skill",
  "link": "/skills#['continuity-management']"
 },
 {
  "text": "IT infrastructure",
  "type": "Skill",
  "link": "/skills#['it-infrastructure']"
 },
 {
  "text": "Information security",
  "type": "Skill",
  "link": "/skills#['information-security']"
 },
 {
  "text": "Modern standards approach",
  "type": "Skill",
  "link": "/skills#['modern-standards-approach']"
 },
 {
  "text": "Systems integration",
  "type": "Skill",
  "link": "/skills#['systems-integration']"
 },
 {
  "text": "Coding and scripting (infrastructure engineer)",
  "type": "Skill",
  "link": "/skills#['coding-and-scripting-infrastructure-engineer']"
 },
 {
  "text": "Coding and scripting",
  "type": "Skill",
  "link": "/skills#['coding-and-scripting']"
 },
 {
  "text": "Customer service management",
  "type": "Skill",
  "link": "/skills#['customer-service-management']"
 },
 {
  "text": "Agile working",
  "type": "Skill",
  "link": "/skills#['agile-working']"
 },
 {
  "text": "Business analysis",
  "type": "Skill",
  "link": "/skills#['business-analysis']"
 },
 {
  "text": "Business process improvement",
  "type": "Skill",
  "link": "/skills#['business-process-improvement']"
 },
 {
  "text": "Business process testing",
  "type": "Skill",
  "link": "/skills#['business-process-testing']"
 },
 {
  "text": "Requirements definition and management",
  "type": "Skill",
  "link": "/skills#['requirements-definition-and-management']"
 },
 {
  "text": "Stakeholder relationship management (business analysis)",
  "type": "Skill",
  "link": "/skills#['stakeholder-relationship-management-business-analysis']"
 },
 {
  "text": "Systems analysis",
  "type": "Skill",
  "link": "/skills#['systems-analysis']"
 },
 {
  "text": "Testing (business analysis)",
  "type": "Skill",
  "link": "/skills#['testing-business-analysis']"
 },
 {
  "text": "Business modelling",
  "type": "Skill",
  "link": "/skills#['business-modelling']"
 },
 {
  "text": "User experience analysis",
  "type": "Skill",
  "link": "/skills#['user-experience-analysis']"
 },
 {
  "text": "Consultancy (business analysis)",
  "type": "Skill",
  "link": "/skills#['consultancy-business-analysis']"
 },
 {
  "text": "Enterprise and business architecture (business analyst)",
  "type": "Skill",
  "link": "/skills#['enterprise-and-business-architecture-business-analyst']"
 },
 {
  "text": "Agile and Lean practices",
  "type": "Skill",
  "link": "/skills#['agile-and-lean-practices']"
 },
 {
  "text": "Commercial management",
  "type": "Skill",
  "link": "/skills#['commercial-management']"
 },
 {
  "text": "Financial management",
  "type": "Skill",
  "link": "/skills#['financial-management']"
 },
 {
  "text": "Life cycle perspective",
  "type": "Skill",
  "link": "/skills#['life-cycle-perspective']"
 },
 {
  "text": "Maintaining delivery momentum",
  "type": "Skill",
  "link": "/skills#['maintaining-delivery-momentum']"
 },
 {
  "text": "Making a process work",
  "type": "Skill",
  "link": "/skills#['making-a-process-work']"
 },
 {
  "text": "Planning",
  "type": "Skill",
  "link": "/skills#['planning']"
 },
 {
  "text": "Team dynamics and collaboration",
  "type": "Skill",
  "link": "/skills#['team-dynamics-and-collaboration']"
 },
 {
  "text": "Financial ownership",
  "type": "Skill",
  "link": "/skills#['financial-ownership']"
 },
 {
  "text": "Government Digital and Data perspective",
  "type": "Skill",
  "link": "/skills#['government-digital-and-data-perspective']"
 },
 {
  "text": "Operational management",
  "type": "Skill",
  "link": "/skills#['operational-management']"
 },
 {
  "text": "Product ownership",
  "type": "Skill",
  "link": "/skills#['product-ownership']"
 },
 {
  "text": "Strategic ownership",
  "type": "Skill",
  "link": "/skills#['strategic-ownership']"
 },
 {
  "text": "Working within constraints",
  "type": "Skill",
  "link": "/skills#['working-within-constraints']"
 },
 {
  "text": "Functional testing",
  "type": "Skill",
  "link": "/skills#['functional-testing']"
 },
 {
  "text": "Non-functional testing",
  "type": "Skill",
  "link": "/skills#['non-functional-testing']"
 },
 {
  "text": "Technical breadth",
  "type": "Skill",
  "link": "/skills#['technical-breadth']"
 },
 {
  "text": "Test analysis",
  "type": "Skill",
  "link": "/skills#['test-analysis']"
 },
 {
  "text": "Programming and build (software engineering)",
  "type": "Skill",
  "link": "/skills#['programming-and-build-software-engineering']"
 },
 {
  "text": "Prototyping",
  "type": "Skill",
  "link": "/skills#['prototyping']"
 },
 {
  "text": "Service support",
  "type": "Skill",
  "link": "/skills#['service-support']"
 },
 {
  "text": "Development process optimisation",
  "type": "Skill",
  "link": "/skills#['development-process-optimisation']"
 },
 {
  "text": "Development process optimisation (management)",
  "type": "Skill",
  "link": "/skills#['development-process-optimisation-management']"
 },
 {
  "text": "Accessibility",
  "type": "Skill",
  "link": "/skills#['accessibility']"
 },
 {
  "text": "Community collaboration (frontend developer)",
  "type": "Skill",
  "link": "/skills#['community-collaboration-frontend-developer']"
 },
 {
  "text": "Leadership and guidance (frontend developer)",
  "type": "Skill",
  "link": "/skills#['leadership-and-guidance-frontend-developer']"
 },
 {
  "text": "Programming and build (frontend developer)",
  "type": "Skill",
  "link": "/skills#['programming-and-build-frontend-developer']"
 },
 {
  "text": "User focus (frontend developer)",
  "type": "Skill",
  "link": "/skills#['user-focus-frontend-developer']"
 },
 {
  "text": "Web performance optimisation",
  "type": "Skill",
  "link": "/skills#['web-performance-optimisation']"
 },
 {
  "text": "Systems design (frontend developer)",
  "type": "Skill",
  "link": "/skills#['systems-design-frontend-developer']"
 },
 {
  "text": "Systems integration (frontend developer)",
  "type": "Skill",
  "link": "/skills#['systems-integration-frontend-developer']"
 },
 {
  "text": "Technical understanding (accessibility)",
  "type": "Skill",
  "link": "/skills#['technical-understanding-accessibility']"
 },
 {
  "text": "Governance and assurance (accessibility)",
  "type": "Skill",
  "link": "/skills#['governance-and-assurance-accessibility']"
 },
 {
  "text": "Leadership and guidance",
  "type": "Skill",
  "link": "/skills#['leadership-and-guidance']"
 },
 {
  "text": "Agile working (content design)",
  "type": "Skill",
  "link": "/skills#['agile-working-content-design']"
 },
 {
  "text": "Content concepts and prototyping",
  "type": "Skill",
  "link": "/skills#['content-concepts-and-prototyping']"
 },
 {
  "text": "Stakeholder relationship management (content design)",
  "type": "Skill",
  "link": "/skills#['stakeholder-relationship-management-content-design']"
 },
 {
  "text": "Strategic thinking (content design)",
  "type": "Skill",
  "link": "/skills#['strategic-thinking-content-design']"
 },
 {
  "text": "User focus (content design)",
  "type": "Skill",
  "link": "/skills#['user-focus-content-design']"
 },
 {
  "text": "User-centred content design",
  "type": "Skill",
  "link": "/skills#['user-centred-content-design']"
 },
 {
  "text": "Digital perspective",
  "type": "Skill",
  "link": "/skills#['digital-perspective']"
 },
 {
  "text": "Evidence- and context-based design",
  "type": "Skill",
  "link": "/skills#['evidence--and-context-based-design']"
 },
 {
  "text": "Prototyping in code",
  "type": "Skill",
  "link": "/skills#['prototyping-in-code']"
 },
 {
  "text": "Tools and software",
  "type": "Skill",
  "link": "/skills#['tools-and-software']"
 },
 {
  "text": "Technical understanding (user-centred design)",
  "type": "Skill",
  "link": "/skills#['technical-understanding-user-centred-design']"
 },
 {
  "text": "Analysis and synthesis",
  "type": "Skill",
  "link": "/skills#['analysis-and-synthesis']"
 },
 {
  "text": "Inclusive research",
  "type": "Skill",
  "link": "/skills#['inclusive-research']"
 },
 {
  "text": "Research",
  "type": "Skill",
  "link": "/skills#['research']"
 },
 {
  "text": "Society and technology",
  "type": "Skill",
  "link": "/skills#['society-and-technology']"
 },
 {
  "text": "Strategic insight",
  "type": "Skill",
  "link": "/skills#['strategic-insight']"
 },
 {
  "text": "User-centred and Agile practices",
  "type": "Skill",
  "link": "/skills#['user-centred-and-agile-practices']"
 }
]