const accessibleAutocomplete = require("accessible-autocomplete");
const { debounce } = require("./helpers");
const { track } = require("./analytics-helpers");

(function init_search() {
  function customSuggest(query, syncResults) {
    const source = require("../data/search-paths.json");
    debounce(track("search_bar", query), 1500);
    syncResults(
      query
        ? source.filter(function (result) {
            var resultContains =
              result.text.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            var endonymContains =
              result.text.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            return resultContains || endonymContains;
          })
        : []
    );
  }
  function inputValueTemplate(result) {
    return result && result.text;
  }

  function suggestionTemplate(result) {
    return (
      "<p class='search-link'><a class='search-link' href='" +
      result.link +
      "'><span class='search-text'>" +
      result.text +
      "</span><br><span class='search-type'>" +
      result.type +
      "</span></a></p>"
    );
  }
  const element = document.querySelector("#my-autocomplete-container");
  const id = "app-site-search__input";
  accessibleAutocomplete({
    element: element,
    id: id,
    cssNamespace: "app-site-search",
    displayMenu: "overlay",
    placeholder: "Search for roles or skills",
    menuAttributes: {
      "aria-labelledby": id,
    },
    confirmOnBlur: false,
    autoselect: true,
    minLength: 3,
    source: customSuggest,
    onConfirm: (result) => {
      const root = location.protocol + "//" + location.host;
      window.location.href = root + result.link;
    },
    templates: {
      inputValue: inputValueTemplate,
      suggestion: suggestionTemplate,
    },
  });
})();
