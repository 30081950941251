function debounce(func, wait) {
  var timeoutId;

  return function () {
    var context = this,
      args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}

module.exports = { debounce };
